// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.u-clearfix::after {
	clear: both;
	content: '';
	display: table;
}

.u-container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
}

[class*="u-frame"] {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.u-fullWidth {
	width: 100%;
}

.u-block {
	display: block;
}

.u-flex {
	display: flex;
}

.u-hidden {
	display: none !important;
}

.u-hiddenVisually {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.u-invisibleScrollBar {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.u-customScrollBar {
	scrollbar-color: rgba(0,0,0,.2) transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: .4rem;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,.2);
		transition: all .25s ease-in-out;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: rgba(0,0,0,.4);
	}
}

.u-textCenter {
	text-align: center;
}

.u-textRight {
	text-align: right;
}

.u-flexJustifyStart {
	justify-content: flex-start;
}

.u-flexJustifyEnd {
	justify-content: flex-end;
}

.u-flexJustifyCenter {
	justify-content: center;
}

.u-flexJustifyBetween {
	justify-content: space-between;
}

.u-flexJustifyAround {
	justify-content: space-around;
}

.u-flexAlignItemsStart {
	align-items: flex-start;
}

.u-flexAlignItemsEnd {
	align-items: flex-end;
}

.u-flexAlignItemsCenter {
	align-items: center;
}

.u-spanRow {
	grid-column: 1 / -1;
}

.u-hiddenOverflow {
	overflow: hidden;
}

.u-noWrap {
	white-space: nowrap;
}

.u-textUppercase {
	text-transform: uppercase;
}

.u-textLowercase {
	text-transform: lowercase;
}
