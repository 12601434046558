.u-frameSm {
	max-width: calc(var(--sizeContentWidthSmall) + var(--sizeContentOffset) * 2);
	padding-left: var(--sizeContentOffset);
	padding-right: var(--sizeContentOffset);
}

.u-frameMd {
	max-width: calc(var(--sizeContentWidthMedium) + var(--sizeContentOffset) * 2);
	padding-left: var(--sizeContentOffset);
	padding-right: var(--sizeContentOffset);
}

.u-frameLg {
	max-width: calc(var(--sizeContentWidthLarge) + var(--sizeContentOffset) * 2);
	padding-left: var(--sizeContentOffset);
	padding-right: var(--sizeContentOffset);
}

.u-frameHd {
	max-width: calc(var(--sizeContentWidthHd) + var(--sizeContentOffset) * 2);
	padding-left: var(--sizeContentOffset);
	padding-right: var(--sizeContentOffset);
}

.u-frameHdOffset {
	$HDOffset: var(--sizeContentOffsetHd, var(--sizeContentOffset));
	max-width: calc(var(--sizeContentWidthHd) + #{$HDOffset} * 2);
	padding-left: #{$HDOffset};
	padding-right: #{$HDOffset};
}