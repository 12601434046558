:root {
	--font-primary: #{$font-primary}, sans-serif;
	--font-secondary: serif;

	/* set base values */
	--textBaseSize: 1rem;
	--textScaleRatio: 1.2;

	/* type scale */
	--textXxs: calc(1em / (var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio)));
	--textXs: calc(1em / (var(--textScaleRatio) * var(--textScaleRatio)));
	--textSm: calc(1em / var(--textScaleRatio));
	--textRg: 1em;
	--textMd: calc(1em * var(--textScaleRatio));
	--textLg: calc(1em * var(--textScaleRatio) * var(--textScaleRatio));
	--textXl: calc(1em * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio));
	--textXxl: calc(1em * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio));
	--textXxxl: calc(1em * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio));
	--textXxxxl: calc(1em * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio) * var(--textScaleRatio));

	--bodyLineHeight: 1.5;
	--headingLineHeight: 1.2;

	@include media('>sm') {
		--textBaseSize: 1.15rem;
	}

	@include media('>md') {
		--textBaseSize: 1.25rem;
	}
}
