:root {
	// CONTENT
	--sizeContentOffset: 1.25rem;
	--sizeContentOffsetHd: 4%;
	--sizeContentWidthHd: 120rem;
	--sizeContentWidthLarge: 87.5rem;
	--sizeContentWidthMedium: 75rem;
	--sizeContentWidthSmall: 61.25rem;

	// INPUTS
	--sizeInputFont: var(--textSm);
	--sizeInputPaddingVertical: var(--spaceSm);
	--sizeInputPaddingHorizontal: var(--spaceMd);
	--sizeInputPadding: var(--sizeInputPaddingVertical) var(--sizeInputPaddingHorizontal);
	--sizeInputBorder: 1px;
	--sizeInputBorderRadius: 0;

	// BUTTONS
	--sizeButtonFont: var(--textSm);
	--sizeButtonPaddingVertical: var(--spaceSm);
	--sizeButtonPaddingHorizontal: var(--spaceLg);
	--sizeButtonPadding: var(--sizeButtonPaddingVertical) var(--sizeButtonPaddingHorizontal);
	--sizeButtonBorder: 1px;
	--sizeButtonBorderRadius: 0;

	// GENERAL
	--sizeGeneralBorderRadius: 0.5rem;
}