.u-spaceUnit {
	margin-bottom: var(--spaceUnit);
}

.u-spaceXxxxs {
	margin-bottom: var(--spaceXxxxs);
}

.u-spaceXxxs {
	margin-bottom: var(--spaceXxxs);
}

.u-spaceXxs {
	margin-bottom: var(--spaceXxs);
}

.u-spaceXs {
	margin-bottom: var(--spaceXs);
}

.u-spaceSm {
	margin-bottom: var(--spaceSm);
}

.u-spaceMd {
	margin-bottom: var(--spaceMd);
}

.u-spaceLg {
	margin-bottom: var(--spaceLg);
}

.u-spaceXl {
	margin-bottom: var(--spaceXl);
}

.u-spaceXxl {
	margin-bottom: var(--spaceXxl);
}

.u-spaceXxxl {
	margin-bottom: var(--spaceXxxl);
}

.u-spaceXxxxl {
	margin-bottom: var(--spaceXxxxl);
}