:root {
	--spaceUnit:  1em;
	--spaceXxxxs: calc(0.125 * var(--spaceUnit));
	--spaceXxxs:  calc(0.25 * var(--spaceUnit));
	--spaceXxs:   calc(0.375 * var(--spaceUnit));
	--spaceXs:    calc(0.5 * var(--spaceUnit));
	--spaceSm:    calc(0.75 * var(--spaceUnit));
	--spaceRg:    var(--spaceUnit);
	--spaceMd:    calc(1.25 * var(--spaceUnit));
	--spaceLg:    calc(2 * var(--spaceUnit));
	--spaceXl:    calc(3.25 * var(--spaceUnit));
	--spaceXxl:   calc(5.25 * var(--spaceUnit));
	--spaceXxxl:  calc(8.5 * var(--spaceUnit));
	--spaceXxxxl: calc(13.75 * var(--spaceUnit));
}
