h1, h2, h3, h4, form legend {
	color: var(--colorTextHeading);
	margin-bottom: var(--spaceXs);
	line-height: var(--headingLineHeight);
}

/* text size */
.u-textXxxl {
	font-size: var(--textXxxl);
}

h1, .u-textXxl {
	font-size: var(--textXxl);
}

h2, .u-textXl {
	font-size: var(--textXxl);
	margin-bottom: var(--spaceSm);
}

h3, .u-textLg {
	font-size: var(--textLg);
}

h4, .u-textMd {
	font-size: var(--textMd);
}

.u-textSm, small {
	font-size: var(--textSm);
}

.u-textXs {
	font-size: var(--textXs);
}

p {
	line-height: var(--bodyLineHeight);
}

a {
	color: var(--colorLinkText);
	text-decoration: none;

	@include on-event {
		color: var(--colorLinkTextActive);
		text-decoration: underline;
	}
}
