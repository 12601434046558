:root {
	// BRAND
	@include defineColorHSL(--colorBrand, 3, 85%, 57%, true);
	@include defineColorHSL(--colorBrandSecondary, 0, 0%, 94%);
	@include defineColorHSL(--colorBodyBg, 0, 0%, 100%);
	@include defineColorHSL(--colorSecondaryBg, 0, 0%, 91%);
	@include defineColorHSL(--colorContrastBg, 203, 28%, 8%);
	@include defineColorHSL(--colorThemeAccent, 0, 0%, 100%);
	--colorBrandBg: #{alpha(var(--colorBrand), .08)};
	--colorShadeBg: rgba(0,0,0,.8);
	--colorOutlines: #dadada;
	--colorOutlinesLight: #f5f5f5;
	--colorHighlighted: #ba0000;
	--starsColor: #fcad00;
	--colorSecondaryBgLight: #{alpha(var(--colorSecondaryBg), .8)};
	--colorBrandLight: #{alpha(var(--colorBrand), .6)};
	--colorBrandLighter: #F47770;

	// ERROR
	@include defineColorHSL(--colorErrorsDefault, 352, 100%, 43%);

	// TEXT
	@include defineColorHSL(--colorText, 0, 0%, 0%);
	--colorTextHeading: #000;
	--colorTextSubtle: #4a4a4a;

	// LINKS
	--colorLinkText: var(--colorBrand);
	--colorLinkTextActive: var(--colorText);

	// INPUTS
	--colorInputBorder: transparent;
	--colorInputBackground: var(--colorSecondaryBgLight);
	--colorInputBorderFocus: var(--colorBrand);
	--colorInputBackgroundFocus: var(--colorSecondaryBgLight);
	--colorInputBackgroundInvalid: var(--colorSecondaryBgLight);

	// BUTTONS
	--colorButtonBg: var(--colorBrand);
	--colorButtonDisabledBg: gray;
	--colorButtonText: white;

	// STATUS COLORS
	--stateInfo: #5895f5;
	--stateSuccess: #4dab00;
	--stateWarning: #f58c59;
	--stateError: #dc2727;

	// ESHOP
	--colorStockIn: var(--stateSuccess);
	--colorStockOut: var(--stateError);

	// FOOTER
	@include defineColorHSL(--colorFooterAccent, 193, 59%, 15%, true);
	--colorFooterAccentAlpha: #{alpha(--colorFooterAccent, .6)};
	--colorFooterAccentLight: #3D5A62;
}
