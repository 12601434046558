/*
	!! DO NOT FORGET TO ADD FONTS TO '@preload.php' FILE AS WELL !!
	----
	Overwrite $font-list object in _font-list.scss file !!
*/

$font-primary: 'Montserrat' !default;

$font-list: (
	$font-primary: (
		'normal': (
			'black': 900,
			'bold': 700,
			'light': 300,
			'medium': 400
		)
	)
) !default;

@import "fonts-settings";

@each $font, $fontMap in $font-list {
	@each $fontStyles, $fontFamilies in $fontMap {
		@each $fontFamily, $fontWeight in $fontFamilies {
			@font-face {
				font-family: #{$font};
				src: font('#{$font}/#{$font}-#{$fontFamily}-#{$fontStyles}.woff2') format('woff2');
				font-weight: #{$fontWeight};
				font-style: #{$fontStyles};
				font-display: fallback;
			}
		}
	}
}
