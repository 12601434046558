[data-tooltip] {
	position: relative;

	&:before {
		content: attr(data-tooltip);
		position: absolute;
		left: 50%;
		bottom: calc(100% + 11px);
		background: #000000de;
		color: #fff;
		transform-origin: bottom center;
		transition: var(--animationBase);
		transform: translateX(-50%);
		padding: 17px;
		font-size: 14px;
		width: 249px;
		text-align: center;
		line-height: 1.4;
		border-radius: 4px;
		border-bottom: 3px solid var(--colorBrand);
		pointer-events: none;
		opacity: 0;
	}

	&:after {
		content: '▾';
		display: block;
		position: absolute;
		left: 50%;
		bottom: 100%;
		transform: translateX(-50%);
		width: 249px;
		font-size: 32px;
		line-height: 17px;
		text-align: center;
		color: var(--colorBrand);
		transition: var(--animationBase);
		pointer-events: none;
		opacity: 0;
	}

	&:hover:before {
		pointer-events: auto;
		opacity: 1;
	}

	&:hover:after {
		pointer-events: auto;
		opacity: 1;
	}
}