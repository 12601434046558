html {
	box-sizing: border-box;
	scroll-behavior: smooth;

	&.is-not-smooth {
		scroll-behavior: unset;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	background: var(--colorBodyBg);
	margin: 0;
	font-size: var(--textBaseSize);
	font-family: var(--font-primary);
	color: var(--colorText);
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: var(--bodyLineHeight);

	&.is-not-overflowed {
		overflow: hidden;
	}
}

/* Remove default padding */
ul,
ol {
	padding: 0;
}

/* Remove default margin */
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
	list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
}

::selection {
	color: text-contrast(var(--colorBrand));
	background: var(--colorBrand);
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}