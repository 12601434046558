:root {
	--layerNegativeZIndex: -1;
	--layerNullZIndex: 0;

	--layerPageZIndex: 1;
	--layerPageSecondaryZIndex: 2;

	--layerHeaderZIndex: 10;

	--layerDropdownZIndex: 20;
	--layerModalZIndex: 21;
	--layerPopoverZIndex: 22;
	--layerTooltipZIndex: 23;
	--cookiePopupLayer: 24;
	--layerSpinnerZIndex: 25;
}
