:root {
	--durationAnimationBase: .25s;
	--easingAnimationBase: ease-in-out;
	--animationBase: all var(--durationAnimationBase) var(--easingAnimationBase);
}





