$font-primary: 'Montserrat';

$font-list: (
	$font-primary: (
		'normal': (
			'extrabold': 800,
			'bold': 700,
			'semibold': 600,
			'medium': 500,
			'regular': 400,
			'extralight': 200
		)
	)
);